import '../layout/index.scss'

import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {graphql} from 'gatsby'
import React, {useEffect} from 'react'
import {ResearchProjectQueryQuery} from 'types/graphtypes'
import {TemplateType} from 'types/basictypes'
import {Researchproject} from 'layout/pages/researchproject'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'
import {countWords} from 'util/countWords'
import {Template} from 'layout/templates/template'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

const ResearchProjectPage: React.FC<TemplateType<
	ResearchProjectQueryQuery
	>> = ({ data, pageContext }) => {
	const {
		page: {
			path: { langcode },
			field_description,
			dataLayerDate,
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_blocks,
				field_market
			}
		},
		allPagePreview: { relatedFragments }
	} = data

	const preview = previewFragmentToPreviewItem(previewFragment)
	const blocks = loadBlocksFromGraph(field_blocks)
	const description = getWysiwygFromField(field_description)
	const marketicon = getImageFromField(field_market?.relationships?.field_iconmarket_logo)

	useEffect(() => {
		// @ts-ignore
		if (window['dataLayer']) {
			// @ts-ignore
			dataLayer.push({
				ContentType: '(icon)',
				WordCount: countWords(blocks),
				ContentTags: preview.tags,
				PublishDate: dataLayerDate
			})
		}
	}, [pageContext.slug])

	return (
		<Template
			preview={preview}
			pageContext={pageContext}
			lang={langcode}
			relatedFragments={relatedFragments}
			relatedJobsFragments={[]}
			content={blocks}
			metaFragment={metaFragment}
		>
			<Researchproject
				blocks={blocks}
				description={description}
				marketicon={marketicon}
			/>
		</Template>
	)
}

export default ResearchProjectPage

export const researchProjectQuery = graphql`
	query ResearchProjectQuery($slug: String!, $allTags: [String]) {
		page: nodeResearchProject(fields: {slug: {eq: $slug}}) {
			dataLayerDate: field_date(formatString: "DD/MM/YYYY")
			field_description {
				processed
			}
			path {
				langcode
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_blocks {...Blocks}
				field_tags {name}
				field_background_image {
					field_overlay_percentage
					...HeroImage
				}
				field_market {
					name
					relationships {
						field_iconmarket_logo {
							...ImageMeta
							relationships {
								field_media_image {
									localFile {
										absolutePath
										image: childImageSharp {
											fixed(width: 180, height: 180, cropFocus: CENTER) {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
					}
				}
				field_research_project_type {
					name
				}
				field_status {
					name
				}
				field_year {
					name
				}
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {tags: {in: $allTags}, has_image: {eq: true}}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}
	}
`
